<template>
  <div>
    <q-form ref="editForm">
      <!-- 공정상세 -->
      <c-card title="LBL0003231" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 신규 공정 -->
            <c-btn v-if="editable" label="LBL0003232" icon="add" @btnClicked="addParent" />
            <!-- 저장 -->
            <c-btn
              v-if="editable"
              :disabled="!saveable"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="data"
              :mappingType="saveType"
              label="LBLSAVE"
              icon="save"
              @beforeAction="saveData"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-6">
            <!-- 공정명 -->
            <c-text
              :editable="editable && dataeditable"
              :required="true"
              label="LBL0003230"
              name="processName"
              v-model="data.processName">
            </c-text>
          </div>
          <div class="col-3">
            <!-- 공정코드 -->
            <c-text
              :editable="editable && dataeditable && !updateMode"
              :required="true"
              label="공정코드"
              name="processCd"
              v-model="data.processCd">
            </c-text>
          </div>
          <div class="col-3">
            <!-- 사업장 -->
            <c-plant
              :required="true"
              :editable="editable && dataeditable"
              label="LBLPLANT"
              name="plantCd"
              v-model="data.plantCd">
            </c-plant>
          </div>
          <div class="col-4">
            <!-- 정렬순서 -->
            <c-text
              :editable="editable && dataeditable"
              :required="true"
              label="LBLSORTORDER"
              name="orderNo"
              v-model="data.orderNo">
            </c-text>
          </div>
          <div class="col-4">
            <!-- PSM대상 -->
            <c-checkbox
              :editable="editable && dataeditable"
              :isFlag="true"
              label="LBL0003234"
              name="psmFlag"
              v-model="data.psmFlag"
            />
          </div>
          <div class="col-4">
            <!-- 사용여부 -->
            <c-checkbox
              :editable="editable && dataeditable"
              :isFlag="true"
              label="LBLUSEFLAG"
              name="useFlag"
              v-model="data.useFlag"
            />
          </div>
          <div class="col-5">
            <!-- 주재료 -->
            <c-text
              :editable="editable && dataeditable"
              label="LBL0003235"
              name="mainMaterial"
              v-model="data.mainMaterial">
            </c-text>
          </div>
          <div class="col-5">
            <!-- 생산품 -->
            <c-text
              :editable="editable && dataeditable"
              label="LBL0003236"
              name="productName"
              v-model="data.productName">
            </c-text>
          </div>
          <div class="col-2">
            <!-- 근로자수 -->
            <c-text
              :editable="editable && dataeditable"
              label="LBL0003237"
              name="workerCnt"
              :suffix="setLabel('LBL0003239')"
              type="number"
              v-model="data.workerCnt">
            </c-text>
          </div>
          <div class="col-12">
            <!-- 공정설명 -->
            <c-textarea
              :counter="true"
              :editable="editable && dataeditable"
              label="LBL0003238"
              name="processDesc"
              :rows="2"
              v-model="data.processDesc">
            </c-textarea>
          </div>
          <div class="col-12">
            <!-- 공정 사진 -->
            <c-upload
              :attachInfo="attachInfoUp"
              :editable="editable && attachable && dataeditable"
              label="LBL0003240">
            </c-upload>
          </div>
          <div class="col-12">
            <!-- 그 밖의 유해위험정보 -->
            <c-upload
              :attachInfo="attachInfo3"
              :editable="editable && attachable && dataeditable"
              label="LBL0003241">
            </c-upload>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import mixinConvert from '@/js/mixin-template-convert'
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-manage',
  mixins: [mixinConvert],
  props: {
    parentProcessCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachable: false,
      attachInfoUp: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UP',
        taskKey: '',
      },
      attachInfo3: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_HAZARD',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      psmYnItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '해당없음' },
      ],
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        mainMaterial: '',
        workerCnt: '',
        productName: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  watch: {
    parentProcessCd() {
      if (this.parentProcessCd) {
        this.rowClick({ processCd: this.parentProcessCd })
      } else {
        this.reset();
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.processCd;
      if (row.processLevelCd == '10') {
        this.lvl1Check = true;
      } else {
        this.lvl1Check = false;
      }
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.attachable = true;
        this.$set(this.attachInfoUp, 'taskKey', _result.data.processCd)
        this.$set(this.attachInfo3, 'taskKey', _result.data.processCd);
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addParent() {
      this.attachable = true;
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '10',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      };
      // this.$emit('isNew');
      this.$set(this.attachInfoUp, 'taskKey', '')
      this.$set(this.attachInfo3, 'taskKey', '');
    },
    reset() {
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.attachable = false;
      this.dataeditable = false;
      this.data = {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
      };
      this.$set(this.attachInfoUp, 'taskKey', '');
      this.$set(this.attachInfo3, 'taskKey', '');
      this.$emit('isNew');
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.processCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId
                  this.data.chgUserId = this.$store.getters.user.userId
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                'MSG0001004', // 동일한 시퀀스 아이디가 존재합니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'POST') {
        this.$set(this.attachInfoUp, 'taskKey', this.data.processCd)
        this.$set(this.attachInfo3, 'taskKey', this.data.processCd);
        this.$set(this.attachInfoUp, 'isSubmit', uid())
        this.$set(this.attachInfo3, 'isSubmit', uid())
        // this.reset();
        this.selectedProcessCd = '';
      }
      this.rowClick({processCd: this.data.processCd});
      this.$emit('searchDetail');
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('searchDetail');
      this.reset();
      this.selectedProcessCd = '';
    },
  }
};
</script>
